import React from "react";
import { Container, Row} from "react-bootstrap";
import { Box } from "../Core";

const Disclaimer = () => {
return(

<Box style={{background: '#4532eb'}}>
<Container style={{background: '#4532eb'}}>
<Row>
      <p style={{fontSize: 14, color: 'white', lineHeight: '18px', padding: 15}}>Startupmag is a brand of Kohdao Ltd. Kohdao Ltd. is registered in England & Wales under No 06261702. Data Protection Registration No: ZA891746. Startupmag does not give financial advice and does not recommend financial services. Startupmag simply provides an alphabetical list of venture capital firms.</p>
    </Row>
</Container>
</Box>
)
}

export default Disclaimer;